#session-detail-page {
  .ion-padding span{
    padding: 2px 5px;
    border-radius: 10px;
    margin-top: 5px;
    color: white;
  }
  .session-track-ionic {
    color: var(--ion-color-primary);
  }

  .session-track-iestec {
    background-color: var(--ion-color-iestec);
  }

  .session-track-msebss {
    background-color: var(--ion-color-msebss);
  }

  .session-track-bbba {
    background-color: var(--ion-color-bbba);
  }

  .session-track-bbba {
    background-color: var(--ion-color-bbba);
  }

  .session-track-enen {
    background-color: var(--ion-color-enen);
  }

  .session-track-incb {
    background-color: var(--ion-color-incb);
  }

  .session-track-libs {
    background-color: var(--ion-color-libs);
  }

  .session-track-isit {
    background-color: var(--ion-color-isit);
  }

  .session-track-rpai {
    background-color: var(--ion-color-rpai);
  }

  .session-track-navigation {
    color: var(--ion-color-navigation);
  }

  .show-favorite {
    position: relative;
  }

  .icon-heart-empty {
    position: absolute;
    top: 5px;
    right: 5px;
    transform: scale(1);
    transition: transform 0.3s ease;
  }

  .icon-heart {
    position: absolute;
    top: 5px;
    right: 5px;
    transform: scale(0);
    transition: transform 0.3s ease;
  }

  .show-favorite .icon-heart {
    transform: scale(1);
  }

  .show-favorite .icon-heart-empty {
    transform: scale(0);
  }

  h1 {
    margin: 0;
  }
}
