#schedule-page {
  ion-fab-button {
    --background: var(--ion-color-step-150, #fff);
    --background-hover: var(--ion-color-step-200, #f2f2f2);
    --background-focused: var(--ion-color-step-250, #d9d9d9);

    --color: var(--ion-color-primary, #3880ff);
  }

  /*
   * Material Design uses the ripple for activated
   * so only style the iOS activated background
   */
  .ios ion-fab-button {
    --background-activated: var(--ion-color-step-250, #d9d9d9);
  }

  .track-pill {
    padding: 2px 5px;
    margin-left: 5px;;
    border-radius: 5px;
    color: white;
  }

  .session-track-iestec {
    background-color: var(--ion-color-iestec);
  }

  .session-track-iestec {
    background-color: var(--ion-color-iestec);
  }

  .session-track-msebss {
    background-color: var(--ion-color-msebss);
  }

  .session-track-bbba {
    background-color: var(--ion-color-bbba);
  }

  .session-track-bbba {
    background-color: var(--ion-color-bbba);
  }

  .session-track-enen {
    background-color: var(--ion-color-enen);
  }

  .session-track-incb {
    background-color: var(--ion-color-incb);
  }

  .session-track-libs {
    background-color: var(--ion-color-libs);
  }

  .session-track-isit {
    background-color: var(--ion-color-isit);
  }

  .session-track-rpai {
    background-color: var(--ion-color-rpai);
  }

  .session-track-jic {
    background-color: var(--ion-color-rpai);
  }

  .session-track-wpa {
    background-color: var(--ion-color-rpai);
  }

  .session-track-navigation {
    color: var(--ion-color-navigation);
  }

  ion-item-sliding.track-iestec ion-label {
    border-left: 4px solid var(--ion-color-iestec);
    padding-left: 10px;
  }
  ion-item-sliding.track-msebss ion-label {
    border-left: 4px solid var(--ion-color-msebss);
    padding-left: 10px;
  }
  ion-item-sliding.track-bbba ion-label {
    border-left: 4px solid var(--ion-color-bbba);
    padding-left: 10px;
  }
  ion-item-sliding.track-eess ion-label {
    border-left: 4px solid var(--ion-color-eess);
    padding-left: 10px;
  }
  ion-item-sliding.track-enen ion-label {
    border-left: 4px solid var(--ion-color-enen);
    padding-left: 10px;
  }
  ion-item-sliding.track-incb ion-label {
    border-left: 4px solid var(--ion-color-incb);
    padding-left: 10px;
  }
  ion-item-sliding.track-libs ion-label {
    border-left: 4px solid var(--ion-color-libs);
    padding-left: 10px;
  }
  ion-item-sliding.track-isit ion-label {
    border-left: 4px solid var(--ion-color-isit);
    padding-left: 10px;
  }
  ion-item-sliding.track-rpai ion-label {
    border-left: 4px solid var(--ion-color-rpai);
    padding-left: 10px;
  }
  ion-item-sliding.track-documentation ion-label {
    border-left: 4px solid var(--ion-color-documentation);
    padding-left: 10px;
  }
  ion-item-sliding.track-navigation ion-label {
    border-left: 4px solid var(--ion-color-navigation);
    padding-left: 10px;
  }
}
